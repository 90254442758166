<template>
  <div :class="$style.container">
    <h3>{{ dealer.name }}</h3>
    <div :class="$style.wrapper">
      <div :class="$style.chat">
        <div :class="$style.messages">
          <div ref="messages" :class="$style.scrollable" @scroll="handleScroll">
            <p v-if="!messages.length" :class="$style.historyClean">
              История переписки пока пуста
            </p>
            <Message
              v-for="(item, index) in messages"
              :key="index"
              :out-message="item.outMessage"
              :text="item.text"
              :attachment="item.attachment"
              :time="item.time"
              :avatar="item.avatar"
              :name="item.name"
              :marked-as-read="item.markedAsRead"
            />
          </div>
        </div>
        <form :class="$style.actions" @submit.prevent="sendMessage">
          <ButtonUpload :files="files" @change="changeFiles" />
          <input
            v-if="!files.length"
            v-model="message"
            :class="$style.input"
            placeholder="Напишите письмо"
          />
          <Button :class="$style.sendButton" type="submit" small rounded>
            <SendIcon />
          </Button>
        </form>
        <div v-if="errors.files" :class="$style.error">{{ errors.files }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import delivery from '@/delivery'
import SendIcon from '@/assets/icons/send.svg?inline'
import Button from '@/components/atoms/Button.vue'
import Message from '@/components/atoms/ChatMessage.vue'
import ButtonUpload from '@/components/atoms/UploadButton.vue'

export default {
  components: {
    SendIcon,
    Button,
    Message,
    ButtonUpload,
  },
  data() {
    return {
      message: '',
      attachment: '',
      files: [],
      errors: {
        files: '',
      },
    }
  },
  computed: {
    ...mapState({
      dealer: (state) => state.chats.dealer,
      managerId: (state) => state.auth.manager.id,
      socketStatus: (state) => state.chats.socketStatus,
    }),
    ...mapGetters({
      messages: 'messageList',
      unreadMessages: 'unreadMessages',
    }),
  },
  watch: {
    messages() {
      this.$nextTick(async () => {
        await this.scrollToMessagesBottom()
      })
    },
    unreadMessages() {
      this.updateMessage()
    },
  },
  async mounted() {
    await this.initWsChat()
    await this.getMessageList(this.$route.params.id)
  },
  beforeDestroy() {
    delivery.AddwineCore.WsChatActions.close()
  },
  methods: {
    ...mapActions(['getMessageList', 'initWsChat', 'getMoreMessageList']),
    scrollToMessagesBottom() {
      const messages = this.$refs.messages
      messages.scrollTop = messages.scrollHeight - messages.clientHeight
    },
    async sendMessage() {
      this.errors.files = ''
      await this.initWsChat()

      if (this.files.length) {
        const resp = await delivery.AddwineCore.ChatsActions.putFile(
          this.$route.params.id,
          this.files[0],
        )
        if (!resp.error) {
          this.attachment = resp.value
          this.message = this.files[0].name
        }
      }

      delivery.AddwineCore.WsChatActions.send({
        text: this.message,
        attachment: this.attachment,
        dealerAccountId: this.$route.params.id,
        managerAccountId: this.dealer.managerAccountId,
        coordinatorId: this.dealer.coordinatorId,
        isReadManager: true,
        isWrittenManager: true,
      })

      this.files = []
      this.attachment = ''
      this.message = ''
    },
    async updateMessage() {
      await this.initWsChat()
      this.unreadMessages.forEach((item) => {
        delivery.AddwineCore.WsChatActions.send({
          id: item.id,
          isReadManager: true,
        })
      })
    },
    async handleScroll(e) {
      const prevScrollHeight = e.target.scrollHeight
      if (e.target.scrollTop <= 0) {
        const result = await this.getMoreMessageList(this.$route.params.id)
        if (result) {
          e.target.scrollTop = e.target.scrollHeight - prevScrollHeight
        }
      }
    },
    changeFiles(files) {
      this.errors.files = ''
      const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB
      const fileSize = files[0] ? files[0].size : 0
      if (fileSize < MAX_FILE_SIZE) {
        this.files = files
      } else {
        this.errors.files = 'Превышен максимальный размер файла(10MB)'
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';

.container {
  flex: 1 1 auto;
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 1.5rem;
  .title {
    margin-bottom: 1.5rem;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    .managerCard {
      order: 1;
      width: 18.5rem;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
    .chat {
      @include card;
      border: 0.063rem solid $light-gray;
      padding: 0;
      order: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      .messages {
        padding: 0 0.125rem;
        .scrollable {
          padding: 1.5rem;
          height: 30rem;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0.5rem;
          }
          &::-webkit-scrollbar-thumb {
            background: $light-gray;
          }
          .historyClean {
            text-align: center;
          }
        }
      }
      .actions {
        padding: 1rem 1.5rem;
        display: flex;
        align-items: center;
        border-top: 0.063rem solid $light-gray;
        .input {
          flex: 1 1 auto;
          border: none;
          outline: none;
          background: $white;
          font-size: 1rem;
          line-height: 1.375rem;
          min-width: 1rem;
          padding-right: 1.5rem;
          &::placeholder {
            color: $dark-gray;
            font-size: 1rem;
            line-height: 1.375rem;
          }
        }
        .sendButton {
          display: flex;
          align-items: center;
          justify-content: center;
          > svg {
            path {
              fill: $white;
            }
          }
        }
      }
      .error {
        font-size: 0.75rem;
        color: $error;
        padding: 0 2rem 1rem;
      }
    }
  }
}
</style>
