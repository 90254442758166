var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style.message, { [_vm.$style.outMessage]: _vm.outMessage }]},[_c('div',{class:_vm.$style.main},[_vm._l((_vm.messageChain),function(chainItem,index){return _c('div',{key:index,class:_vm.$style.text},[(!_vm.attachment)?_c('div',[_vm._v(_vm._s(chainItem))]):_vm._e(),(_vm.checkPic(_vm.attachment))?_c('div',[_c('img',{attrs:{"src":_vm.$configData.s3_link + _vm.attachment}})]):(_vm.attachment)?_c('div',[_c('a',{attrs:{"href":_vm.$configData.s3_link + _vm.attachment,"download":chainItem}},[_vm._v(" "+_vm._s(chainItem)+" "),_c('Icon',{attrs:{"name":"fileDownload"}})],1)]):_vm._e()])}),_c('div',{class:_vm.$style.time},[(!_vm.outMessage && _vm.markedAsRead)?_c('Icon',{class:_vm.$style.markedAsReadIcon,attrs:{"name":"check"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.name + ' ' + _vm.time)+" ")],1)],2),(!_vm.outMessage)?_c('div',{class:_vm.$style.avatar,style:({
      backgroundImage: `url(${
        _vm.avatar
          ? _vm.$configData.s3_link + _vm.avatar
          : require('@/assets/images/default_avatar.webp')
      })`
    })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }