<template>
  <div :class="[$style.uploadButton, { [$style.block]: files.length }]">
    <input
      id="actualBtn"
      type="file"
      hidden
      :files="files"
      @change="$emit('change', $event.target.files)"
    />
    <label class="label" for="actualBtn">
      <PaperClip />
      <div v-if="files.length" :class="$style.files">
        <div v-for="(f, i) in files" :key="i">{{ f.name }}</div>
      </div>
    </label>
  </div>
</template>

<script>
import PaperClip from '@/assets/icons/paperclip.svg?inline'

export default {
  components: {
    PaperClip
  },
  props: {
    files: {
      type: [Array, FileList],
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="scss" module>
.uploadButton {
  display: flex;
  padding: 0.875rem 1rem 0.875rem 0;
  cursor: pointer;

  label {
    cursor: pointer;
    display: flex;
    .files {
      margin-left: 2rem;
    }
  }
  &.block {
    flex: 1 1 auto;
  }
}
</style>
