<template>
  <div :class="[$style.message, { [$style.outMessage]: outMessage }]">
    <div :class="$style.main">
      <div
        v-for="(chainItem, index) in messageChain"
        :key="index"
        :class="$style.text"
      >
        <div v-if="!attachment">{{ chainItem }}</div>
        <div v-if="checkPic(attachment)">
          <img :src="$configData.s3_link + attachment" />
        </div>
        <div v-else-if="attachment">
          <a :href="$configData.s3_link + attachment" :download="chainItem">
            {{ chainItem }}
            <Icon name="fileDownload" />
          </a>
        </div>
      </div>
      <div :class="$style.time">
        <Icon
          v-if="!outMessage && markedAsRead"
          :class="$style.markedAsReadIcon"
          name="check"
        />
        {{ name + ' ' + time }}
      </div>
    </div>
    <div
      v-if="!outMessage"
      :class="$style.avatar"
      :style="{
        backgroundImage: `url(${
          avatar
            ? $configData.s3_link + avatar
            : require('@/assets/images/default_avatar.webp')
        })`
      }"
    ></div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
export default {
  components: {
    Icon
  },
  props: {
    outMessage: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    text: {
      type: [String, Array],
      default: ''
    },
    attachment: {
      type: [String, Array],
      default: ''
    },
    time: {
      type: String,
      default: () => {
        const now = new Date()
        return `${now.getHours()}:${now.getMinutes()}`
      }
    },
    markedAsRead: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    messageChain() {
      return typeof this.text === 'string' ? [this.text] : this.text
    }
  },
  methods: {
    checkPic(attachment) {
      const reg = /.+(\.webp|\.png|\.jpg|\.jpeg|\.gif)$/i
      if (!attachment) {
        return false
      }
      return reg.test(attachment)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.message {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  .main {
    margin-right: 1rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .text {
      padding: 0.5rem 1rem;
      background: $light-gray;
      border-radius: 0.25rem;
      &:not(:first-child) {
        margin-top: 1.375rem;
      }
      a {
        text-decoration: none;
        color: $black-gray;
      }
      img {
        width: 100%;
        max-height: 15rem;
      }
    }
    .time {
      margin-top: 1rem;
      margin-right: -4rem;
      color: $dark-gray;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
  .avatar {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &.outMessage {
    justify-content: flex-start;
    .main {
      margin-left: 0.5rem;
      margin-right: 0;
      align-items: flex-start;
      .text {
        position: relative;
        color: $white;
        background: $cyan;
        border-radius: 0.375rem;
        border-bottom-left-radius: 0;
        a {
          color: $white;
        }
      }
      .time {
        align-self: flex-start;
        display: flex;
        align-items: center;
        margin-left: -0.5rem;
        .markedAsReadIcon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>
